import {ProductPage} from "../components/ProductPage"
import {CheckoutPage} from "../components/CheckoutPage"
import {CheckoutLabelComponent} from "../components/CheckoutLabel"
import {FindOutMoreModal} from "../components/FindOutMoreModal"
import {useEffect, useState} from "react"
import {Spacer} from "@raylo-tech/raylopay-ui"
import CREATE_ACCEPTABLE_DATA_MUTATION from "./graphql/createAcceptableDataMutation"
import {useMutation} from "@apollo/client"

export const DevelopmentToolsComponent = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [productPageJWT, setProductPageJWT] = useState(null)
  const [checkoutPageJWT, setCheckoutPageJWT] = useState(null)
  const [previewCheckoutPageJWT, setPreviewCheckoutPageJWT] = useState(null)

  const [createAcceptableData, {data: acceptableDataResponse}] =
    useMutation(CREATE_ACCEPTABLE_DATA_MUTATION,
      {variables: {merchantId: `${process.env.REACT_APP_DEV_TOOLS_MERCHANT_ID}`}})

  useEffect(() => {
    createAcceptableData()
  }, [createAcceptableData])

  useEffect(() => {
    setProductPageJWT(acceptableDataResponse?.createAcceptableData?.previewMerchantCheckoutItemPayload)
    setCheckoutPageJWT(acceptableDataResponse?.createAcceptableData?.merchantCheckoutPayload)
    setPreviewCheckoutPageJWT(acceptableDataResponse?.createAcceptableData?.previewMerchantCheckoutPayload)
  }, [acceptableDataResponse])

  const merchantId = process.env.REACT_APP_DEV_TOOLS_MERCHANT_ID
  const handleModalOpen = () => {
    setModalOpen(true)
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const createCheckoutRedirectUrl = `https://app.staging.raylo.com/merchant/${merchantId}/create-checkout/${checkoutPageJWT}`

  return (
    <>
      <ProductPage
        token={productPageJWT}
        merchantId={merchantId}
        onSubmit={handleModalOpen}
        onCheckoutUnavailable={() => { console.log('Product page widget - Raylo Pay is unavailable') }}
        onPriceLoaded={({recurringAmount, initialAmount}) => console.log(`Product page widget - price loaded. Recurring amount: ${recurringAmount}, initial amount: ${initialAmount}`)}
        onWidgetLoaded={(data) => console.log('Product page widget loaded: ', data)}
      />
      <Spacer height={24}/>
      <CheckoutPage
        token={previewCheckoutPageJWT}
        merchantId={merchantId}
        handleModalOpen={handleModalOpen}
        onCheckoutUnavailable={() => { console.log('Checkout info widget - Raylo Pay is unavailable') }}
        onPriceLoaded={({recurringAmount, initialAmount}) => console.log(`Checkout info widget - price loaded. Recurring amount: ${recurringAmount}, initial amount: ${initialAmount}`)}
        onWidgetLoaded={(data) => console.log('Checkout info widget loaded: ', data)}
      />
      <Spacer height={24}/>
      <CheckoutLabelComponent
        token={previewCheckoutPageJWT}
        merchantId={merchantId}
        onCheckoutUnavailable={() => { console.log('Checkout payment option widget - Raylo Pay is unavailable') }}
        onWidgetLoaded={(data) => console.log('Checkout payment option widget loaded: ', data)}
      />
      <Spacer height={24}/>

      {modalOpen && <FindOutMoreModal closeModal={handleCloseModal}/>}

      <button onClick={() => window.location.href = createCheckoutRedirectUrl}>Create Checkout</button>
    </>
  )

}
