import { IDimensionsInfo } from "components/types";
import { RefObject, useEffect, useState } from "react";

interface FontFace {
  // cf. https://drafts.csswg.org/css-font-loading/#fontface -- there are other properties but we don't need them here
  family: string;
}

interface FontFaceLoadingDoneEvent {
  // cf. https://drafts.csswg.org/css-font-loading/#fontfacesetloadevent
  fontfaces: FontFace[];
}

export const useDimensions = (targetRef: RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState<IDimensionsInfo>({width: undefined, height: undefined})

  useEffect(() => { // eslint-disable-line react-hooks/exhaustive-deps
    if (targetRef.current) {
      const trySetDimensions = () => {
        const {offsetWidth: width, offsetHeight: height} = targetRef.current!;
        if (width && width !== dimensions.width && height && height !== dimensions.height) {
          setDimensions({width, height});
        }
      }

      trySetDimensions();

      if (typeof IntersectionObserver !== 'undefined' && !targetRef.current.offsetWidth && !targetRef.current.offsetHeight) {
        // dimensions 0,0 likely indicate that the component was not actually rendered
        // like due to a parent element having display:none (e.g. a different checkout option may be the default one)
        // but this could change later (e.g. Raylo Pay being selected as checkout option and it's section expanded / made visible)
        // create an intersection observer so that if/when it comes into view  we can report the dimensions

        // new IntersectionObserver() without a 2nd argument considers the whole document as the intersection viewport
        new IntersectionObserver((entries) => {
          if (entries.some(e => e.isIntersecting)) {
            trySetDimensions();
          }
        }).observe(targetRef.current);
      }

      // fonts sometimes/often load after the element shows, and loading of fonts can affect the layout and thus the size of the element
      document.fonts?.addEventListener('loadingdone', (ev) => {
        const e = ev as any as FontFaceLoadingDoneEvent;
        // the draft spec has e.fontfaces but MDN doesn't yet, so probably this property is still not implemented in all browsers
        if (!e.fontfaces || e.fontfaces.some(f => f.family.startsWith('Raylo'))) {
          setTimeout(trySetDimensions, 1);
        }
      });
    }
  });

  return dimensions;
};
