import React, { StrictMode } from "react"
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import App from "./App";
import "./index.css";

// eslint-disable-next-line
import Widgets from "./widget";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ERROR_TRACKING_ENVIRONMENT,

  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ['localhost', /\.raylopay\.com/],
    }),
    new Replay({
    maskAllText: false,
    maskAllInputs: true,
    blockAllMedia: false,
  })],
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE_FLOAT) || 0.0,
  replaysOnErrorSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE_FLOAT) || 0.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <StrictMode>
    <App {...window.xprops} />
  </StrictMode>
)
