import constate from "constate"

const useApp = () => {
  const checkoutPageWidgetItems = [
    'Choose a Monthly rolling – cancel anytime option',
    'Or choose a 12, 24, or 36 month term',
    `Free upgrade or return when you're ready`,
    'No hidden fees. Interest free',
    'Lifetime warranty included',
    'Rated Excellent on TrustPilot'
  ]

  return {
    checkoutPageWidgetItems
  }
}

const [AppProvider, useAppContext] = constate(useApp)
export {AppProvider, useAppContext}
