import { gql } from "@apollo/client";

export const PREVIEW_CHECKOUT_ITEM = gql`
  query WidgetsRepoPreviewMerchantCheckoutItem(
    $merchantId: ID!
    $payload: String!
  ) {
    previewMerchantCheckoutItem(merchantId: $merchantId, payload: $payload) {
      checkoutItem {
        variant {
          displayName
        }
        merchant {
          displayName
        }
        costSummary {
          initial {
            totalAmount {
              valueAfterTax {
                value
                currencySymbol
                formattedValue
              }
            }
          }
          recurring {
            totalAmount {
              valueAfterTax {
                value
                formattedValue
              }
            }
          }
          termLength
        }
      }
      errors {
        code
        field
        message
      }
    }
  }
`;
