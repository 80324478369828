import { ICostSummary, IPrice } from "components/types";

const decimalZerosRegex = /\.00$/;

export function getFormattedAmounts(costSummary: ICostSummary | undefined): IPrice | undefined {
    const recurringAmount = costSummary?.recurring?.totalAmount?.valueAfterTax?.formattedValue;
    const initialAmount = costSummary?.initial?.totalAmount?.valueAfterTax?.formattedValue?.replace(decimalZerosRegex, '') ?? '£0';
    return recurringAmount
        ? { initialAmount, recurringAmount }
        : undefined;
}
