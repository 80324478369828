import { useEffect } from "react"
import constate from "constate"
import { MU_TOKEN } from "../graphQl/token"
import store from "store"
import { useMutation } from "@apollo/client"

const useAuthentication = () => {
  const [getToken, { data }] = useMutation(MU_TOKEN, {
    onCompleted: (data) => {
      const token = data?.authenticateClient?.clientAuthToken
      store.set("token", token)
    },
    variables: {
      clientId: `${process.env.REACT_APP_CLIENT_ID}`
    }
  })

  useEffect(() => {
    getToken();
  }, [getToken]);
  const authToken = data?.authenticateClient?.clientAuthToken

  return {
    authToken,
  }
}

const [AuthProvider, useAuthContext] = constate(useAuthentication)
export { AuthProvider, useAuthContext }
