import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import store from "store";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_ENDPOINT}`,
});

export const authLink = setContext((_, { headers }) => {
  const token = store.get("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
