import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import { GlobalFontFaceStyles } from "@raylo-tech/raylopay-ui";
import client from "./apollo-client";
import { ApolloProvider } from "@apollo/client";
import { ProductPage } from "./components/ProductPage";
import { CheckoutPage } from "./components/CheckoutPage";
import { FindOutMoreModal } from "./components/FindOutMoreModal";
import { CheckoutLabelComponent } from "./components/CheckoutLabel";
import { AppProvider } from "./hooks/useAppContext";
import {AuthProvider, useAuthContext} from "./hooks/useAuthentication"
import {DevelopmentToolsComponent} from "./developmentTools/developmentTools"

function App({
  JWTToken,
  merchantId,
  handleModalOpen,
  checkoutPage,
  checkoutLabel,
  productPage,
  findOutMoreModal,
  handleCloseModal,
  onCheckoutUnavailable,
  onPriceLoaded,
  disableAnalytics,
  onWidgetLoaded
}) {
  const Application = ({children}) => {
    const { authToken } = useAuthContext();
    return (!!authToken && children)
  };

  const showDevTools = () => {
    const isLocalIntegration = Boolean(process.env.REACT_APP_LOCAL_INTEGRATION)
    return !isLocalIntegration && ['development', 'deployPreview', 'devTools'].includes(process.env.REACT_APP_ENVIRONMENT);
  }

  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <GlobalFontFaceStyles />
      <ApolloProvider client={client}>
        <AuthProvider>

          <AppProvider JWTToken={JWTToken}>
            <Application>
              {productPage && (
                <ProductPage
                  token={JWTToken}
                  merchantId={merchantId}
                  onSubmit={handleModalOpen}
                  handleCloseModal={handleCloseModal}
                  onCheckoutUnavailable={onCheckoutUnavailable}
                  onPriceLoaded={onPriceLoaded}
                  disableAnalytics={disableAnalytics}
                  onWidgetLoaded={onWidgetLoaded}
                />
              )}
              {checkoutPage && (
                <CheckoutPage
                  token={JWTToken}
                  merchantId={merchantId}
                  handleModalOpen={handleModalOpen}
                  onCheckoutUnavailable={onCheckoutUnavailable}
                  onPriceLoaded={onPriceLoaded}
                  disableAnalytics={disableAnalytics}
                  onWidgetLoaded={onWidgetLoaded}
                />
              )}
              {checkoutLabel && (
                <CheckoutLabelComponent
                  token={JWTToken}
                  merchantId={merchantId}
                  onCheckoutUnavailable={onCheckoutUnavailable}
                  onWidgetLoaded={onWidgetLoaded}
                />
              )}
              {findOutMoreModal && (
                <FindOutMoreModal closeModal={handleCloseModal} />
              )}

              {showDevTools() && <DevelopmentToolsComponent />}
            </Application>
          </AppProvider>
        </AuthProvider>
      </ApolloProvider>
    </StyleSheetManager>
  );
}

export default App;
