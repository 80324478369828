import { ProductPageWidget } from "@raylo-tech/raylopay-ui";
import { useEffect, useState, useRef } from "react";
import { useQuery } from "@apollo/client";
import { PREVIEW_CHECKOUT_ITEM } from "../graphQl/previewCheckoutItem";
import { useDimensions } from "../hooks/useDimensions";
import { track } from "../utils/segment"
import { ILoadedCallback, IPrice, IPriceCallback, IUnavailableCallback } from "./types"
import { getFormattedAmounts } from "utils/costSummaries";

type ISubmit = { (): void }

type IProductPage = {
  onSubmit: ISubmit,
  token: string,
  merchantId: string,
  onCheckoutUnavailable?: IUnavailableCallback,
  onPriceLoaded?: IPriceCallback,
  disableAnalytics?: boolean,
  onWidgetLoaded?: ILoadedCallback
}

export const ProductPage = ({ onSubmit, token, merchantId, onCheckoutUnavailable, onPriceLoaded, onWidgetLoaded, disableAnalytics }: IProductPage) => {
  const [merchantName, setMerchantName] = useState<string>();
  const [formattedPrices, setFormattedPrices] = useState<IPrice>();

  const targetRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(targetRef);

  const { data, loading } = useQuery(PREVIEW_CHECKOUT_ITEM, {
    variables: {
      merchantId,
      payload: token,
    },
  });

  useEffect(() => {
    const checkoutItem = data?.previewMerchantCheckoutItem?.checkoutItem

    const { merchant, costSummary } = checkoutItem || {}
    const formattedPrices = getFormattedAmounts(costSummary);
    setFormattedPrices(formattedPrices);
    setMerchantName(merchant?.displayName);

    if(costSummary && !loading && !disableAnalytics) {
      track('Product Widget Viewed', {merchant_name: merchant.displayName});
    }

    if (!checkoutItem && !loading) {
      onCheckoutUnavailable?.();
    }

    if (formattedPrices && !loading) {
      onPriceLoaded?.(formattedPrices);
    }
  }, [data, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formattedPrices && dimensions.width) {
      onWidgetLoaded?.({
        available: true,
        price: formattedPrices,
        dimensions
      });
    }
  }, [dimensions, formattedPrices]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    onSubmit();
    if(!disableAnalytics) {
      track('Product Widget Learn More Clicked', {merchant_name: merchantName})
    }
  };

  if (!formattedPrices) { return null; }

  return (
    <div ref={targetRef}>
      <ProductPageWidget
        formattedMonthlyPrice={formattedPrices.recurringAmount}
        formattedInitialAmount={formattedPrices.initialAmount}
        linkOnClick={handleClick}
      />
    </div>
  );
};
