import { useQuery } from "@apollo/client"
import { PricePoint } from "@raylo-tech/raylopay-ui"
import { useEffect, useState, useRef } from "react"
import { PREVIEW_MERCHANT_CHECKOUT } from "../graphQl/previewMerchantCheckout"
import { useDimensions } from "../hooks/useDimensions";

import { ILoadedCallback, IPrice, IUnavailableCallback } from "./types"
import { getFormattedAmounts } from "utils/costSummaries";

type ICheckoutLabel = {
  token: string,
  merchantId: string,
  onCheckoutUnavailable?: IUnavailableCallback,
  onWidgetLoaded?: ILoadedCallback
}

export const CheckoutLabelComponent = ({token, merchantId, onCheckoutUnavailable, onWidgetLoaded}: ICheckoutLabel) => {
  const [formattedPrices, setFormattedPrices] = useState<IPrice>();

  const targetRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(targetRef);

  const {data, loading, error} = useQuery(PREVIEW_MERCHANT_CHECKOUT, {
    variables: {
      merchantId,
      payload: token
    }
  })

  useEffect(() => {
    const { costSummary } = data?.previewMerchantCheckout?.checkout || {}
    const formattedPrices = getFormattedAmounts(costSummary);
    setFormattedPrices(formattedPrices);

    if (!formattedPrices && !loading) {
      onCheckoutUnavailable?.();
    }
  }, [data, loading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formattedPrices && dimensions.width) {
      onWidgetLoaded?.({
        available: true,
        price: formattedPrices,
        dimensions
      });
    }
  }, [dimensions, formattedPrices]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || error) {
    return null
  }

  return (
    <div ref={targetRef}>
      <PricePoint dataTestId={'checkoutLabelPricePoint'} formattedMonthlyPrice={formattedPrices?.recurringAmount}/>
    </div>
  )
}
