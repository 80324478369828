import { useEffect, useState, useRef } from "react";
import { CheckoutPageWidget } from "@raylo-tech/raylopay-ui";
import { useAppContext } from "../hooks/useAppContext";
import { useDimensions } from "../hooks/useDimensions";
import { useQuery } from "@apollo/client";
import { PREVIEW_MERCHANT_CHECKOUT } from "../graphQl/previewMerchantCheckout";
import {track} from "../utils/segment"

import { ILoadedCallback, IPrice, IPriceCallback, IUnavailableCallback } from "./types"
import { getFormattedAmounts } from "utils/costSummaries";

type ICheckoutPage = {
  token: string,
  merchantId: string,
  handleModalOpen: () => void,
  onCheckoutUnavailable?: IUnavailableCallback,
  onPriceLoaded?: IPriceCallback,
  disableAnalytics?: boolean,
  onWidgetLoaded?: ILoadedCallback
}

export const CheckoutPage = ({ token, merchantId, handleModalOpen, onCheckoutUnavailable, onPriceLoaded, disableAnalytics, onWidgetLoaded }: ICheckoutPage) => {
  const { checkoutPageWidgetItems } = useAppContext();
  const [merchantName, setMerchantName] = useState<string>();
  const [formattedPrices, setFormattedPrices] = useState<IPrice>();

  const targetRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(targetRef);

  const { data, loading } = useQuery(PREVIEW_MERCHANT_CHECKOUT, {
    variables: {
      merchantId,
      payload: token,
    },
  });

  useEffect(() => {
    const checkout = data?.previewMerchantCheckout?.checkout;

    const { costSummary, merchant } = checkout || {};
    const formattedPrices = getFormattedAmounts(costSummary);
    setFormattedPrices(formattedPrices);
    setMerchantName(merchant?.displayName);

    if(!disableAnalytics && checkout && !loading) {
      track('Checkout Widget Viewed', {merchant_name: merchant.displayName})
    }
    if (!checkout && !loading && onCheckoutUnavailable) {
      onCheckoutUnavailable();
    }
    if (formattedPrices && !loading) {
      onPriceLoaded?.(formattedPrices);
    }
  }, [data, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formattedPrices && dimensions.width) {
      onWidgetLoaded?.({
        available: true,
        price: formattedPrices,
        dimensions,
      });
    }
  }, [dimensions, formattedPrices]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    handleModalOpen();
    if(!disableAnalytics) {
      track('Checkout Widget Learn More Clicked', {merchant_name: merchantName})
    }
  };

  if (!formattedPrices) { return null; }

  return (
    <div ref={targetRef}>
      <CheckoutPageWidget
        formattedMonthlyPrice={formattedPrices.recurringAmount}
        formattedInitialAmount={formattedPrices.initialAmount}
        linkOnClick={handleClick}
        items={checkoutPageWidgetItems}
      />
    </div>
  );
};
