import {gql} from '@apollo/client'

const CREATE_ACCEPTABLE_DATA_MUTATION = gql`
  mutation createAcceptableDataMutation($merchantId: ID!) {
    createAcceptableData {
      merchantCheckoutPayload(merchantId: $merchantId)
      previewMerchantCheckoutPayload(merchantId: $merchantId)
      previewMerchantCheckoutItemPayload(merchantId: $merchantId)
    }
  }
`

export default CREATE_ACCEPTABLE_DATA_MUTATION
